<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import _debounce from 'lodash.debounce';

import {
	
} from "@/state/helpers";

export default {
	page: {
		title: "Miembros",
		meta: [
			{
				name: "description",
				content: appConfig.description,
			},
		],
	},
	components: {
		Layout,
		PageHeader,
		
	},
	data() {
		return {
			title: "",
			items: [],
			showModal: false,
			isLoading: false,
			fullPage: true,
			canCancel: false,
			useSlot: false,
			color: "#007bff",
			bgColor: "#ffffff",
			height: 128,
			width: 128,
			members:[],
			member:{},
			submitted:false,
			confirmDelete:false,
			currentPage:1,
			perPage:8,
			rows:0,
      userId: null,
      query: ''
		};
	},
	
	mounted() {
    this.loadMembers();
	},
	created(){
		this.debounceMembers = _debounce(this.searchMembers.bind(this), 1000);
	},
	methods: {
		
    loadMembers(){
			this.isLoading = true;
			let loader = this.$loading.show();
      this.members = [
        {id: '123123', name: 'Federico Gonzales', email: 'fgonzales@domain.com', registrationDate: new Date(), tier:'Gold', activePoints: 100, totalPoints: 4545},
				{id: '123124', name: 'Maria Santana', email: 'msantana@domain.com', registrationDate: new Date(), tier:'Bronze', activePoints: 30, totalPoints: 90},
        {id: '123125', name: 'Matias Perez', email: 'mperez@domain.com', registrationDate: new Date(), tier:'Bronze', activePoints: 10, totalPoints: 87},
        {id: '123126', name: 'Pedro Lopez', email: 'plopez@domain.com', registrationDate: new Date(), tier:'Gold', activePoints: 560, totalPoints: 1233},
				{id: '123127', name: 'Juana Barco', email: 'jbarco@domain.com', registrationDate: new Date(), tier:'Gold', activePoints: 360, totalPoints: 1239},
        {id: '123128', name: 'Juan Ramirez', email: 'jramirez@domain.com', registrationDate: new Date(), tier:'Gold', activePoints: 1233, totalPoints: 12334},
        {id: '123129', name: 'Pablo Martinez', email: 'pmartinez@domain.com', registrationDate: new Date(), tier:'Silver', activePoints: 123, totalPoints: 1356},
      ]
      this.isLoading = false;
				loader.hide();
			/*
      let loader = this.$loading.show();
      const params={
				q: `where[role]=host&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}&populate[0][path]=group&populate[0][select]=_id name`,
			}
			this.getUsers(params).then((users)=>{
				if(users&& users.data){
					this.user= {
                        role:'host'
                    };
					this.submitted= false;
					this.users= users.data;
					this.rows= users.totalCount
			}
			}).catch(()=>{
				this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title") });
			}).finally(()=>{
				this.isLoading = false;
				loader.hide();
			});
      */
      
		},

		onConfirmAddMemberClicked(){
			this.submitted = true;
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			}

			
		},

		onConfirmEditMemberClicked(){
			this.submitted = true;
			
		},

		onCreateOrEditMemberClicked() {
			if(this.user._id) {
				this.onConfirmEditUserClicked();
			}else {
				this.onConfirmCreateUserClicked();
			}
		},

		onDeleteMemberClicked(userId){
			this.confirmDelete = true;
			this.userId = userId
		},

		onEditMemberClicked(user){
			const cloneUser = Object.assign({}, user);
			this.member = cloneUser;
			this.showModal= true;
		},

		onConfirmRemoveMemberlicked(){
			
				this.isLoading = true;
				this.userId = null;
		},

		onMemberListPageClicked() {
				this.loadMembers();
		},

		onCancelCreateOrEditMemberClicked() {
				this.showModal = false;
				this.member ={};
				this.userId = null;
		},

		onCreateMemberClicked(){
			this.member={};
			this.showModal = true;
			
		},

		searchMembers(query){
			
      this.query = query;

			/*let loader = this.$loading.show();
			this.isLoading = true;
			this.users=[];
			if(query){
				this.currentPage = 1;
						const params={
							q: `where[role]=host&where[name][$regex]=.*${query}.*&where[name][$options]=i&limit=${this.perPage}&skip=${this.perPage * (this.currentPage-1)}&populate[0][path]=group&populate[0][select]=_id name`,
						}
						this.getUsers(params).then((users)=>{
				if(users&& users.data){
								this.users= users.data;
								this.rows= users.totalCount;
				}
				}).catch(()=>{
							this.$notify({ type: 'error', text: this.$t("users.get_users_error"),title:  this.$t("users.title_host") });
						}).finally(()=>{
							this.isLoading = false;
							loader.hide();
						});
			}else{
				this.currentPage = 1;
				loader.hide();
				this.loadUsers();
			}*/
			},
	}
};
</script>

<template>
	<Layout>
		<PageHeader title="Miembros" :items="items" />
			<div class="row">
				<div class="col-12">
					<div class="card">
					<div class="card-body">
							<div class="row ">
								<div class="col-sm-4">
										<div class="search-box me-2 mb-0 d-inline-block">
											<div class="position-relative">
													<input
													v-model="query"
													type="text"
													class="form-control"
													:placeholder="$t('common.search')"
													@input="debounceMembers($event.target.value)"
													/>
													<i class="bx bx-search-alt search-icon"></i>
											</div>
										</div>
								</div>
								<div class="col-sm-8">
									<div class="text-sm-end">
										<button
												type="button"
												class="btn btn-primary mb-0 me-0"
												@click="onCreateMemberClicked">
												<i class="mdi mdi-plus me-1"></i> Agregar Miembro
										</button>
									</div>
								</div>
								<!-- end col-->
							</div>
					</div>
					<div class="col-lg-12">
						<div class="table-responsive" v-if="members.length> 0">
							<table  id="my-table" class="table align-middle table-nowrap table-hover mb-0" style="background-color: white;">
									<thead class="table-light">
											<tr>
													<th scope="col">Id</th>
													<th scope="col">{{$t('customers.name')}}</th>
													<th scope="col">Email</th>
													<th scope="col">Puntos Activos</th>
													<th scope="col">Puntos Totales</th>
													<th scope="col">Nivel</th>
													<th scope="col"></th>
											</tr>
									</thead>
											<tbody>
													<tr v-for="member in members" :key="member.id">
															<td>{{member.id}}</td>
															<td>{{member.name}}</td>
															<td>{{member.email}}</td>
															<td>{{member.activePoints}}</td>
															<td>{{member.totalPoints}}</td>
															<td>{{member.tier}}</td>
															<td class="text-end">
																<i class="fas fa-user-circle text-primary me-1 interact"></i>
																
																<b-dropdown
																		class="card-drop"
																		variant="white"
																		right
																		menu-class="dropdown-menu-end"
																		toggle-class="p-0">
																		<template v-slot:button-content>
																			<i class="mdi mdi-dots-horizontal"></i>
																		</template>

																		<b-dropdown-item>
																			<i class="fas fa-star text-primary me-1"></i>
																			Asignar a Nivel
																		</b-dropdown-item>

																		<b-dropdown-item>
																			<i class="fas fa-edit text-success me-1"></i>
																			Gestionar puntos
																		</b-dropdown-item>

																		<b-dropdown-item >
																			<i class="fas fa-trash-alt text-danger me-1"></i>
																			Remover
																		</b-dropdown-item>
																	</b-dropdown>

															</td>
													</tr>
											</tbody>
							</table>
					</div>
					<div class="row mb-4" v-if="rows > perPage">
						<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
										<ul class="pagination pagination-rounded mb-0">
												<b-pagination
														@input="onMemberListPageClicked"
														v-model="currentPage"
														:total-rows="rows"
														:per-page="perPage"></b-pagination>
										</ul>
								</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
